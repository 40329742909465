import { Stack, Link, useTheme, Text, TextField, Spinner, SpinnerSize, PrimaryButton, IDetailsRowProps } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DataTable, ITableColumn, SanitizedText } from '../../../../../../components';
import { TableType } from '../../../../../../enums';
import { useGetJobByGuid } from '../../../../../../hooks';
import { Context } from '../../../../../../utils';
import { useGetOmlItems } from '../../hooks/useGetOmlItems';
import { usePublishClientResponse } from '../../hooks/usePublishClientReponse';
import { AuditQueryAttachment, AttachmentDropZone } from '../attachments/Attachment';
import { QueryComment } from './QueryComment';
import { formatDate } from '../../../../../../utils';
import resolvedPicture from './Resolved.png';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../../../../../navigation';

interface IAuditQueriesPageProps {
    jobGuid: string;
    toggle: () => void;
}

export const AuditQueriesPage: FunctionComponent<IAuditQueriesPageProps> = ({ jobGuid, toggle }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { dataJobs } = useGetJobByGuid({ guid: jobGuid });
    const { response, isFetching, isLoading } = useGetOmlItems(dataJobs?.data?.id);
    const { isPublishInProgress, publishResponse } = usePublishClientResponse();
    const context = useContext(Context);
    const searchParams = useSearchParams();
    const navigate = useNavigate();

    const [isNavigatedNotFromIic, setIsNavigatedNotFromIic] = useState(false);

    useEffect(() => {
        if (searchParams[0].get("showQueries") === "true" && !!searchParams[0].get("jobGuid")) {
            setIsNavigatedNotFromIic(true);
        }
    }, [searchParams]);

    const isResponsePublished = useMemo(() => !!dataJobs?.data?.dateARSent ?
        (dataJobs?.data?.dispatchQueries?.some((x: any) => x.dateSent) && dataJobs?.data?.dispatchQueries?.filter((x: any) => x.dateSent).every((x: any) => x.dateResponse)) :
        (dataJobs?.data?.initialQueries?.some((x: any) => x.dateSent) && dataJobs?.data?.initialQueries?.filter((x: any) => x.dateSent).every((x: any) => x.dateResponse)), [dataJobs?.data]);

    
    const lastResponseDate = useMemo(() => formatDate(!isResponsePublished ? null : !!dataJobs?.data?.dateARSent ?
        dataJobs?.data?.dispatchQueries.filter((x: any) => !!x.dateResponse).sort((x: any, y: any) => x.dateResponse < y.dateResponse ? 1 : -1)[0].dateResponse :
        dataJobs?.data?.initialQueries.filter((x: any) => !!x.dateResponse).sort((x: any, y: any) => x.dateResponse < y.dateResponse ? 1 : -1)[0].dateResponse, 'dd/MM/yyyy'), [dataJobs?.data, isResponsePublished]);

    const withHoldingQueries = useMemo<any[]>(() => (response as any[])?.filter(x => !x.isNonFundamental), [response]);
    const nonWithHoldingQueries = useMemo<any[]>(() => (response as any[])?.filter(x => x.isNonFundamental), [response]);

    const showPublishButton = useMemo(() => (!!dataJobs?.data?.dateARSent ?
        dataJobs?.data?.dispatchQueries?.some((x: any) => x.dateSent) :
        dataJobs?.data?.initialQueries?.some((x: any) => x.dateSent)) && ((withHoldingQueries ?? []).some(x => x.cleared !== 'Yes') || (nonWithHoldingQueries ?? []).some(x => x.cleared !== 'Yes')),
    [dataJobs?.data, withHoldingQueries, nonWithHoldingQueries]);

    const columns = useMemo((): ITableColumn[] => [
        {
            key: 'number',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            fieldName: 'number',
            onRender: (item, idx, column) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text style={{ color: item.cleared === 'Yes' ? theme.schemes?.default?.palette.blackTranslucent40 : theme.schemes?.default?.semanticColors.bodyText }}>
                        {item.isNonFundamental ? !!item.uiOrder ? String.fromCharCode((item.uiOrder ?? 0)) : String.fromCharCode((idx ?? 0) + 97) : !!item.uiOrder ? (item.uiOrder ?? 0) : (idx ?? 0) + 1}
                    </Text>
                </Stack>
            )
        },
        {
            key: 'text',
            name: formatMessage({ id: 'withholdingQueries' }),
            minWidth: 256,
            styles: {
                root: {paddingTop: 0}},
            fieldName: 'text',
            onRender: (item, _, column) => (
                <Stack horizontal tokens={{ childrenGap: 16 }} 
                    styles={{ root: { maxWidth: '100%' } }}>
                    <Stack.Item align="center">
                        <SanitizedText data={item?.text}
                            color={item.cleared === 'Yes' ? theme.schemes?.default?.palette.blackTranslucent40 : theme.schemes?.default?.semanticColors.bodyText}
                            styles={{
                                root: {
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellypsis',
                                    height: '100%',
                                }
                                }} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        {item.cleared === 'Yes' && <img style={{ height: '2.1rem' }} src={`${resolvedPicture}`} />}
                    </Stack.Item>
                </Stack>
            )
        },
        {
            key: 'published',
            name: formatMessage({ id: 'published' }),
            minWidth: 128,
            fieldName: 'published',
            onRender: (item, _, column) => (
                <Stack horizontal horizontalAlign='center' grow={1}>
                    <QueryComment comment={item.lastClientComment ?? item.clientComment} disabled={isResponsePublished || item.cleared === 'Yes'} itemId={item.id} jobId={dataJobs?.data?.id} />
                    <AuditQueryAttachment jobId={dataJobs?.data?.id} fundId={dataJobs?.data?.fund?.id} clientId={dataJobs?.data?.client?.id} tableType={TableType.AuditQueriesForClients}
                        year={dataJobs?.data?.year} itemId={item.id} hasAttachments={item.hasAttachments} isDeletingEnabled={!(isResponsePublished || item.cleared === 'Yes')}/>
                </Stack>)
        }
    ], [dataJobs?.data]);

        const onRenderRow = useCallback(
            (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
                if (!props || !defaultRender) {
                    //technically these may be undefined...
                    return null;
                }

                const row = defaultRender(props);

                if (props?.item?.cleared === 'Yes' || isResponsePublished) {
                    return row;
                }

                return (
                    <AttachmentDropZone
                        tableType={TableType.AuditQueriesForClients}
                        itemId={props?.item?.id}
                        jobId={dataJobs?.data?.id}
                        fundId={dataJobs?.data?.fund?.id}
                        clientId={dataJobs?.data?.client?.id}>
                        {row}
                    </AttachmentDropZone>
                );
            },
            [isResponsePublished, dataJobs?.data]
        );

        if (!dataJobs?.data || isPublishInProgress) {
            return (
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                    <Link
                        style={{ color: theme.schemes?.default?.palette.blue }}
                        onClick={() => {
                            isNavigatedNotFromIic ? navigate(-1) : toggle();
                        }}>
                        {formatMessage({ id: 'back' })}
                    </Link>
                    <Spinner style={{ height: '100%' }} size={SpinnerSize.large} />
                </Stack>
            );
        }

    return (
        <Stack tokens={{ childrenGap: 12 }} styles={{ root: { height: '100%' } }}>
            <Link style={{ color: theme.schemes?.default?.palette.blue }} onClick={() => { isNavigatedNotFromIic ? navigate(-1) : toggle(); }}>{formatMessage({ id: 'back' })}</Link>
            <Stack tokens={{ childrenGap: 12 }} styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} horizontal>
                <Stack tokens={{ childrenGap: 12 }} styles={{ root: { width: '100%' } }}>
                    <Stack tokens={{ childrenGap: 16 }} styles={{ root: {margin: '0.7rem'}}} horizontal>
                        <Stack.Item styles={{ root: { width: '15%' } }} align={'center'}>
                            <Text theme={theme.schemes?.default}>{formatMessage({ id: 'clientName' })}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                            <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={dataJobs?.data?.client.name} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                        <Stack.Item styles={{ root: { width: '15%' } }} align={'center'}>
                            <Text theme={theme.schemes?.default}>{formatMessage({ id: 'fundName' })}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                            <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={dataJobs?.data?.fund.name} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                        <Stack.Item styles={{ root: { width: '15%' } }} align={'center'}>
                            <Text theme={theme.schemes?.default}>{formatMessage({ id: 'jobYear' })}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                            <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={dataJobs?.data?.year} />
                        </Stack.Item>
                    </Stack>
                    {(isResponsePublished || dataJobs?.data?.initialQueries?.filter((x: any) => x.dateSent).length > 0) &&
                    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                        <Stack.Item styles={{ root: { width: '15%' } }} align={'center'}>
                            {isResponsePublished && <Text theme={theme.schemes?.default}>{formatMessage({ id: 'dateSubmitted' })}</Text>}
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                            {isResponsePublished ?
                                <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={lastResponseDate} /> :
                                showPublishButton && <PrimaryButton theme={theme.schemes?.default} text={formatMessage({ id: 'submitResponse' })} onClick={() => publishResponse(dataJobs?.data?.id)} />
                                }
                        </Stack.Item>
                            </Stack>}
                </Stack>
                {(!context?.tenantInfo?.IsWhiteLabel) && <Stack styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.black, height: 'max-content', margin: '0.7rem', padding: '1%' } }}>
                    <SanitizedText color={theme.schemes?.default?.semanticColors.bodyText} data={formatMessage({ id: "blQueriesText" })} />
                </Stack>}
            </Stack>
            <Stack styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }}>
                <Stack.Item styles={{ root: { paddingLeft: '2%', paddingTop: '0.5%', paddingBottom: '0.5%', backgroundColor: theme.schemes?.default?.palette.blackTranslucent40 } }}>
                    <Text styles={{ root: { fontWeight:'bold' }}} theme={theme.schemes?.default}>{formatMessage({ id: 'withholdingQueries' })}</Text>
                </Stack.Item>
                <Stack.Item style={{ margin: '2%'}}>
                    {isFetching || isLoading ? <Spinner /> : !withHoldingQueries ? <Text>{formatMessage({ id: 'noData' })}</Text> :
                        <DataTable
                            isHeaderVisible={false}
                            initialColumns={columns}
                            columns={columns}
                            items={withHoldingQueries}
                            onRenderRow={onRenderRow}
                        />
                        }
                </Stack.Item>
            </Stack>
            <Text styles={{ root: { marginLeft: '2%', fontWeight: 'bold' } }} theme={theme.schemes?.default}>{formatMessage({ id: 'nonWithholdingQueriesText' })}</Text>
            <Stack styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }}>
                <Stack.Item styles={{ root: { paddingLeft: '2%', paddingTop: '0.5%', paddingBottom: '0.5%', backgroundColor: theme.schemes?.default?.palette.blackTranslucent40 } }}>
                    <Text styles={{ root: { fontWeight: 'bold' } }} theme={theme.schemes?.default}>{formatMessage({ id: 'nonWithholdingQueries' })}</Text>
                </Stack.Item>
                <Stack.Item style={{ margin: '2%' }}>
                    {isFetching || isLoading ? <Spinner /> : !nonWithHoldingQueries ? <Text>{formatMessage({ id: 'noData' })}</Text> :
                        <DataTable
                            isHeaderVisible={false}
                            initialColumns={columns}
                            columns={columns}
                            items={nonWithHoldingQueries}
                            onRenderRow={onRenderRow}
                        />
                    }
                </Stack.Item>
            </Stack>
        </Stack>
    );
}